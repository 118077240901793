import i18n from "@/setup/i18n";
import store from "@/store";
import { Model } from "@vuex-orm/core";
import Vendor from "./Vendor";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";

import { create } from "@bufbuild/protobuf";
import {
  CustomerController,
  CustomerLessPermissionRequestSchema,
  CustomerRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/vendor_pb";
const customerClient = useDresskareGrpcClient(CustomerController);

export default class Customer extends Model {
  static entity = "customer";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      keycloakUserUuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      firstName: this.string(),
      lastName: this.string(),
      email: this.string(),
      postalCode: this.string(),
      vendors: this.attr(null),
      vendorsObj: this.hasManyBy(Vendor, "vendors"),
      depositNumber: this.number(0),
      productNumber: this.number(0),
      phoneNumber: this.string(),
      address: this.string(),
      city: this.string(),
      rib: this.attr(null),
      enabled: this.boolean(true),
      isSourcedByDresskare: this.boolean(true),
      // INFO - AM - 05/10/2022 - This field is only set when create call on a customer that already exist. Do not use it for an other pupose
      isAlreadyExisting: this.boolean(true),
      depositVendorsObj: this.attr(null),
      acceptedLegalTermAt: this.attr(null),
      legalTermsUpdatedAt: this.attr(null),
      ageRange: this.attr(null),
      acceptHomeTakeAway: this.boolean(true),
      acceptSendPostal: this.boolean(true),
      knowDresskare: this.string(null).nullable(),
      registerToCustomerNewsletter: this.boolean(true),
      acceptContactDresskare: this.boolean(true),
      preferedContactWay: this.string(null).nullable(),
      gender: this.number(0).nullable(),
      latitude: this.number(null).nullable(),
      longitude: this.number(null).nullable(),
    };
  }

  static getCustomers = async (filters) => {
    try {
      const response = await customerClient.list({ Filters: filters });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static create = async (form, options = { showSuccess: true }) => {
    try {
      const response = await customerClient.create(
        create(CustomerRequestSchema, form),
        {}
      );

      this.insertOrUpdate({ data: response });
      if (options.showSuccess) {
        if (response.isAlreadyExisting) {
          store.dispatch(
            "notifications/showWarningNotification",
            i18n.t("customer.alreadyExist")
          );
        } else {
          store.dispatch(
            "notifications/showSuccessNotification",
            i18n.t("successCreate")
          );
        }
      }
      return response;
    } catch (err) {
      console.error(err);
      store.dispatch(
        "notifications/showErrorNotification",
        i18n.t("customer.errorCreate")
      );
      return null;
    }
  };

  static getCustomerByUuid = async (customerUuid, withAll = false) => {
    let customerFromStore;
    if (withAll) {
      customerFromStore = this.query().withAll().find(customerUuid);
    } else {
      customerFromStore = this.find(customerUuid);
    }

    if (customerFromStore) {
      return customerFromStore;
    } else {
      try {
        const response = await customerClient.retrieve(
          { uuid: customerUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };

  static getCustomerByKeycloakUuid = async (keycloakUserUuid) => {
    const customerFromStore = this.query()
      .where("keycloakUserUuid", keycloakUserUuid)
      .get();

    if (customerFromStore.length) {
      return customerFromStore[0];
    } else {
      try {
        const response = await customerClient.retrieveByKeycloakUuid(
          { keycloakUserUuid: keycloakUserUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };

  static updateInDb = async (form, options = { showSuccess: true }) => {
    try {
      const response = await customerClient.update(
        create(CustomerRequestSchema, form),
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });

      if (options.showSuccess) {
        store.dispatch(
          "notifications/showSuccessNotification",
          i18n.t("successUpdate")
        );
      }

      return response;
    } catch (err) {
      console.error(err);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static acceptLegalterm = async (form) => {
    try {
      const response = await customerClient.acceptLegalTerm(
        { uuid: form.uuid },
        {}
      );

      if (!response) {
        return null;
      }
      this.update({
        where: response.uuid,
        data: response,
      });

      return response;
    } catch (err) {
      console.error(err);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static setSentSetails = async (form) => {
    try {
      const response = await customerClient.setSentSetails(
        {
          uuid: form.uuid,
          acceptSendPostal: form.acceptSendPostal,
          acceptHomeTakeAway: form.acceptHomeTakeAway,
        },
        {}
      );

      if (!response) {
        return null;
      }
      this.update({
        where: response.uuid,
        data: response,
      });

      return response;
    } catch (err) {
      console.error(err);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static updateLessPermissioninDb = async (form) => {
    try {
      // INFO - AM - 25/11/2023 - This update has less permission because it accept only some non critique fields
      const response = await customerClient.updateLessPermission(
        create(CustomerLessPermissionRequestSchema, form),
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });

      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successUpdate")
      );

      return response;
    } catch (err) {
      console.error(err);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };
}
